module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.7.0_gatsby@5.7.0_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Notta","short_name":"Notta","start_url":"/","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5a82a935fa969efc06f13812b38286bc"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gatsby-cloud@5.7.0_gatsby@5.7.0_webpack@5.94.0/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/tools/online-audio-converter":["Cross-Origin-Embedder-Policy: require-corp","Cross-Origin-Opener-Policy: same-origin"],"/tools/online-video-converter":["Cross-Origin-Embedder-Policy: require-corp","Cross-Origin-Opener-Policy: same-origin"],"/tools/online-vocal-remover":["Cross-Origin-Embedder-Policy: require-corp","Cross-Origin-Opener-Policy: same-origin"],"/en/tools/online-audio-converter":["Cross-Origin-Embedder-Policy: require-corp","Cross-Origin-Opener-Policy: same-origin"],"/en/tools/online-video-converter":["Cross-Origin-Embedder-Policy: require-corp","Cross-Origin-Opener-Policy: same-origin"],"/en/tools/online-vocal-remover":["Cross-Origin-Embedder-Policy: require-corp","Cross-Origin-Opener-Policy: same-origin"]}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.7.0_gatsby@5.7.0/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#3089F0","showSpinner":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_gatsby@5.7.0_i18next@22.4.5_react-i18next@12.1.1_react@18.2.0/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","ja","es","pt","fr","id","cs","de","it","nl","pl","tr","uk","ko","ar","fa","th","hi","vi"],"redirect":false,"generateDefaultLanguagePage":true,"defaultLanguage":"ja","fallbackLanguage":"en","siteUrl":"https://test-12154205867b06a3.notta.ai/","trailingSlash":"never","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"ns":["dashboard","subscribe"]},"pages":[{"matchPath":"/:lang?/:uid*","getLanguageFromPath":true,"languages":["en","ja","es","pt","fr","id","cs","de","it","nl","pl","tr","uk","ko","ar","fa","th","hi","vi"]},{"matchPath":"/preview","getLanguageFromPath":true,"languages":["en","ja"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.7.0_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@4.7.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
